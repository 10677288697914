import { Component, OnInit } from '@angular/core';
import { HashTable } from 'angular-hashtable';
import { LevelService } from 'src/app/account/services/level.service';
import { StudentAccountService } from 'src/app/account/services/student-account.service';
import { Auth } from 'src/app/shared/auth';
import { Cache } from 'src/app/shared/cache';
import { Helper } from 'src/app/shared/helper';
import { Message } from 'src/app/shared/message';
import { StudentService } from 'src/app/student/services/student.service';
import { environment } from 'src/environments/environment';
import { AcademicSettingService } from '../../services/academic-setting.service';
import { CourseService } from '../../services/course.service';
import { GlobalService } from 'src/app/shared/services/global.service';
import { ApplicationSettingService } from 'src/app/adminision/services/application-setting.service';
import { Request } from 'src/app/shared/request';
import { UserProfileService } from 'src/app/user-profile/user-profile.service'
import { AcademicYearService } from 'src/app/account/services/academic-year.service';
import { Title } from '@angular/platform-browser';
import { DivisionService } from 'src/app/account/services/division.service';
import { TermService } from 'src/app/account/services/term.service';

@Component({
  selector: 'app-report-course-register',
  templateUrl: './report-course-register.component.html',
  styleUrls: ['./report-course-register.component.scss']
})
export class ReportCourseRegisterComponent implements OnInit {

  filter: any = {};
  $: any = $;
  applicationService: any = ApplicationSettingService;
  levels: any = [];
  level_id:any;

  divisions: any = [];
  courses: any = [];
  groups: any = [];
  sections: any = [];
  academicYears: any = [];
  filter_search:any = {};
  doc: any = document;
  terms: any = [];
  data:any = [];
  division_id:any;
  term_id:any;
  //
  public searchKey: string;
  public studentSearchDialogShow = false;
  public studentSearchDialogLoader = false;
  public isWait = false;
  public isSelected = false;
  public timeoutId;
  public students: any = [];


  constructor(
    private courseService: CourseService,
    private academicService: AcademicYearService,
    private termService:TermService,
    private titleService: Title,
    private globalService: GlobalService,
    private applicationSettingService: ApplicationSettingService)
     {
      this.groups = this.applicationSettingService.groups().subscribe((res: any) => {
        this.groups = res;
      })

      this.courses = this.courseService.getopenCourses().subscribe((res: any) => {
        this.courses = res;
      })
    this.titleService.setTitle("BNSU"+ " - " + Helper.trans('print result'))
        this.applicationSettingService.queueRequests();
      var self = this;
      Request.fire(false, () => {
      });

}




excel() {
  this.doc.exportExcel();
}

printContent() {
  this.doc.printJs();
}
  ngOnInit() {
    this.getYear();
    $('#division_id').on('change' , ()=>{
      this.division_id = $('#division_id').val();
    })
    $('#term_id').on('change' , ()=>{
      this.term_id = $('#term_id').val();
    })
    $('#level_id').on('change' , ()=>{
      this.level_id = $('#level_id').val();
    })
    this.levels = Cache.get(LevelService.LEVEL_PREFIX);
    this.divisions = Cache.get(DivisionService.DIVISION_PREFIX);
    this.terms = Cache.get(TermService.TERPM_PREFIX);
  }
  CourseData:any=[]
  years=[]
  getYear(){
    this.globalService.get('adminision/get_academic_years').subscribe( (res: any) => {
      this.years=res;
  })
}
  getData( ){
    this.CourseData=[]

   this.globalService.get('academic/register_courses/count',this.filter).subscribe( (res: any) => {

   this.CourseData =res

 });
}

}
